@import '/node_modules/swiper/swiper.scss';
@import '/node_modules/swiper/modules/autoplay/autoplay.scss';
@import '/node_modules/swiper/modules/navigation/navigation.scss';
@import '/node_modules/swiper/modules/pagination/pagination.scss';
@import "src/app/styles/_variables.scss";

html {
  overscroll-behavior-y: none;
  font-size: 12px;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: $sm) {
    font-size: 14px;
  }

  @media (min-width: $lg) { 
    font-size: 16px;
  }
}

body {
  overscroll-behavior-y: none;
    background-color: $black-color;
    &:has(.requires-no-scroll) {
      overflow: hidden;
    }
}

a {
  cursor: pointer;
  transition: color .15s ease-in-out;
  &:hover {
    color: $primary-color;
  }
}

img {
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

strong {
  @extend %ff-unity-headline-bold;
}

@font-face {
  font-family: 'Unity Headline';
  src:
    local("Unity Headline"),
    url('/assets/fonts/TCCC-UnityHeadline-Regular.woff2') format('woff2'),
    url('/assets/fonts/TCCC-UnityHeadline-Regular.woff') format('woff'),
    url('/assets/fonts/TCCC-UnityHeadline-Regular.ttf') format('truetype'),
    url('/assets/fonts/TCCC-UnityHeadline-Regular.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
font-family: 'Unity Headline Light';
src:
    local("Unity Headline Light"),
    url('/assets/fonts/TCCC-UnityHeadline-Light.woff2') format('woff2'),
    url('/assets/fonts/TCCC-UnityHeadline-Light.woff') format('woff'),
    url('/assets/fonts/TCCC-UnityHeadline-Light.ttf') format('truetype'),
    url('/assets/fonts/TCCC-UnityHeadline-Light.eot') format('embedded-opentype');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Unity Headline Medium';
src:
    local("Unity Headline Medium"),
    url('/assets/fonts/TCCC-UnityHeadline-Medium.woff2') format('woff2'),
    url('/assets/fonts/TCCC-UnityHeadline-Medium.woff') format('woff'),
    url('/assets/fonts/TCCC-UnityHeadline-Medium.ttf') format('truetype'),
    url('/assets/fonts/TCCC-UnityHeadline-Medium.eot') format('embedded-opentype');
font-weight: 600;
font-style: normal;
font-display: swap;
}

@font-face {
  font-family: 'Unity Headline Bold';
  src:
    local("Unity Headline Bold"),
    url('/assets/fonts/TCCC-UnityHeadline-Bold.woff2') format('woff2'),
    url('/assets/fonts/TCCC-UnityHeadline-Bold.woff') format('woff'),
    url('/assets/fonts/TCCC-UnityHeadline-Bold.ttf') format('truetype'),
    url('/assets/fonts/TCCC-UnityHeadline-Bold.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// custom radio for history
.custom-radio {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid #999999;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 2px;
  left: 2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #C51235;
}
// * Text
.ff-unity-headline-bold {
  @extend %ff-unity-headline-bold;
}
.ff-unity-headline-medium {
  @extend %ff-unity-headline-medium;
}
.ff-unity-headline-light {
  @extend %ff-unity-headline-light;
}
.ff-unity-headline-regular {
  @extend %ff-unity-headline-regular;
}

.fs36-lh40 {
  @extend %fs36-lh40;
}
.fs32-lh40 {
  @extend %fs32-lh40;
}
.fs28-lh40 {
  @extend %fs28-lh40;
}
.fs26-lh40 {
  @extend %fs26-lh40;
}
.fs24-lh32 {
  @extend %fs24-lh32;
}
.fs20-lh24 {
  @extend %fs20-lh24;
}
.fs18-lh24 {
  @extend %fs18-lh24;
}
.fs16-lh24 {
  @extend %fs16-lh24;
}
.fs14-lh24 {
  @extend %fs14-lh24;
}
.fs12-lh16 {
  @extend %fs12-lh16;
}

.text-bold {
  font-weight: bold;
}

.color-red {
  color: $primary-color;
}
.color-red-dark {
  color: $primary-dark-color;
}
.color-black {
  color: $black-color;
}
.color-black-light {
  color: $black-light-color;
}
.color-white {
  color: $white-color;
}
.color-coal {
  color: $coal-color;
}

.letter-0_4px {
  letter-spacing: -0.4px;
}

.cr-pointer{
  cursor: pointer;
}

// * Buttons
.primary-button {
    @extend %ff-unity-headline-bold;
    @extend %fs14-lh24;
    display: inline-block;
    width: auto;
    height: auto;
    min-width: 10rem;
    min-height: 3rem;
    border-radius: 1.5rem;
    border: 0;
    margin: 0;
    padding: 0.375rem 0.75rem;
    color: $white-color;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    transition: background 0.3s, border 0.3s;
    background-color: $primary-dark-color;
    border: 1px solid $primary-dark-color;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white-color;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }
}

.secondary-button {
    @extend %ff-unity-headline-bold;
    @extend %fs14-lh24;
    display: inline-block;
    color: $primary-dark-color;
    width: auto;
    height: auto;
    min-width: 10rem;
    min-height: 3rem;
    border-radius: 1.5rem;
    border: 0;
    margin: 0;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    text-align: center;
    background-color: $white-color;
    text-decoration: none;
    vertical-align: middle;
    transition: background 0.3s, border 0.3s, color 0.3s;
    border: 1px solid $primary-dark-color;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white-color;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }
}

.btn:hover {
  color: $primary-color;
  [class^="icon-"]::before,
  [class^="icon-"]::after {
    color: $primary-color;
  }
}

// * Utils
.dropdown-menu {
  width: 8em;
  min-height: 9rem;
  border-radius: 0.9rem;
  padding: 0.8rem;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  border: none;
  z-index: $_z1;

  &::after {
      @extend %ff-unity-headline-bold;
      content: "▲";
      line-height: 1;
      color: $white-color;
      display: inline-block;   
      position: absolute;
      left: 5rem;
      top: -1rem;
      transform: scaleX(2);
      text-shadow: 0 -1px 1px rgba(0,0,0,0.1);
      font-size: 1.25rem;
      background-color: transparent;
  }
  .dropdown-item {
      padding: 0.5rem 0.8rem;
      background-color: transparent;
      &:hover {
          color: $primary-dark-color;
      }
  }
  li:not(:last-child)::after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      opacity: 0.15;
      background-color: $gray-dark-color;
  };
}

.custom-accordion {
  .accordion-item {
      margin-bottom: 0.5rem;
      border-radius: 0.625rem;
      border: none;
      background-color: $white-color;
      box-shadow: 0 2px 8px 0 rgba(175,175,175,0.28);
      &.active {
          border-radius: 0;

      }
  }
  .accordion-button {
      background-color: transparent;
      box-shadow: none;
      color: $black-color;
  }
  
  .icon-up3x,
  .icon-down3x {
    font-size: 0.75rem;
  }
    
  hr {
      opacity: 1;
      border-top: 1px solid $gray-dark-color;
  }
}

.header-component {
  width: 100%;
  height: 17.5rem;
  background-image: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%), var(--asset);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// * Remove arrows in input type:number
input[type=number].no-spinner::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.after-none::after {
  content: none;
}

.trunk-1-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trunk-3-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.z1 {
  z-index: $_z1;
}
.z2 {
  z-index: $_z2;
}
.z3 {
  z-index: $_z3;
}
.z4 {
  z-index: $_z4;
}
.z5 {
  z-index: $_z5;
}
.z6 {
  z-index: $_z6;
}
.z7 {
  z-index: $_z7;
}
.z8 {
  z-index: $_z8;
}
.z9 {
  z-index: $_z9;
}
.z10 {
  z-index: $_z10;
}

.mt-3_5rem {
  margin-top: 3.5rem;
}

// * ICOMOON
.fz-2rem {
  font-size: 1.6rem;
  @media (min-width: $md) { 
    font-size: 2rem;
  }
}
.fz-1_5rem {
  font-size: 1.5rem;
}
.fz-1_2rem {
  font-size: 1.2rem;
}
.fz-1rem { 
  font-size: 1rem;
  line-height: inherit;
}
.fz-0_75rem { 
  font-size: 0.75rem;
}

.cp-logo {
  height: 1.6rem;
  @media (min-width: $md) { 
    height: 2rem;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: $primary-color;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

@font-face {
    font-family: 'icomoon';
    src:  url('assets/fonts/icomoon.eot?8tsgxo');
    src:  url('assets/fonts/icomoon.eot?8tsgxo#iefix') format('embedded-opentype'),
      url('assets/fonts/icomoon.woff2?8tsgxo') format('woff2'),
      url('assets/fonts/icomoon.ttf?8tsgxo') format('truetype'),
      url('assets/fonts/icomoon.woff?8tsgxo') format('woff'),
      url('assets/fonts/icomoon.svg?8tsgxo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-Home3x:before {
    content: "\e93d";
    color: #fff;
  }
  .icon-language3x:before {
    content: "\e93c";
    color: #fff;
  }
  .icon-Logout3x:before {
    content: "\e90a";
  }
  .icon-error-icon3x:before {
    content: "\eb3c";
    color: #c51235;
  }
  .icon-ok-icon3x:before {
    content: "\eb3d";
    color: #1dab40;
  }
  .icon-waring-icon3x:before {
    content: "\eb3e";
    color: #f5a623;
  }
  .icon-edit3x:before {
    content: "\eb3f";
    color: #c51235;
  }
  .icon-plus3x:before {
    content: "\e96d";
    color: #c51235;
  }
  .icon-store3x:before {
    content: "\e96e";
  }
  .icon-upload3x:before {
    content: "\e96f";
  }
  .icon-canjea3x:before {
    content: "\e901";
    color: #c51235;
  }
  .icon-download3x:before {
    content: "\e900";
    color: #fff;
  }
  .icon-qr-canjear3x .path1:before {
    content: "\e970";
    color: rgb(255, 255, 255);
  }
  .icon-qr-canjear3x .path2:before {
    content: "\e971";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path1:before {
    content: "\e972";
    color: rgb(255, 255, 255);
  }
  .icon-qr-virtualizar3x .path2:before {
    content: "\e973";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path3:before {
    content: "\e974";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path4:before {
    content: "\e975";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path5:before {
    content: "\e976";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path6:before {
    content: "\e977";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path7:before {
    content: "\e978";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path8:before {
    content: "\e979";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path9:before {
    content: "\e97a";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path10:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-qr-virtualizar3x .path11:before {
    content: "\e97c";
    margin-left: -1em;
    color: rgb(45, 52, 71);
  }
  .icon-min-circle3x:before {
    content: "\e913";
    color: #c51235;
  }
  .icon-plus-circle3x:before {
    content: "\e914";
    color: #c51235;
  }
  .icon-reintentar3x:before {
    content: "\e915";
    color: #c51235;
  }
  .icon-digitalizar3x:before {
    content: "\e905";
    color: #c51235;
  }
  .icon-historial3x:before {
    content: "\e906";
    color: #fff;
  }
  .icon-Retornapp-logo3x:before {
    content: "\e907";
    color: #fff;
  }
  .icon-profile3x1:before {
    content: "\ea60";
  }
  .icon-up3x:before {
    content: "\ea61";
    color: #c51235;
  }
  .icon-down3x:before {
    content: "\ea62";
    color: #c51235;
  }
  .icon-Ok3x:before {
    content: "\ea52";
  }
  .icon-dot-empty3x:before {
    content: "\e90b";
    color: #fff;
  }
  .icon-dot-fill3x:before {
    content: "\e90c";
    color: #fff;
  }
  .icon-FAQ3x:before {
    content: "\e90d";
    color: #fff;
  }
  .icon-Hide3x:before {
    content: "\e90e";
    color: #666;
  }
  .icon-icon-collapesd3x:before {
    content: "\e90f";
    color: #fff;
  }
  .icon-Left3x:before {
    content: "\e902";
    color: #c51235;
  }
  .icon-llogout3x:before {
    content: "\e908";
    color: #c51235;
  }
  .icon-map3x .path1:before {
    content: "\e93e";
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path2:before {
    content: "\e940";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path3:before {
    content: "\e941";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path4:before {
    content: "\e942";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path5:before {
    content: "\e943";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path6:before {
    content: "\e944";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x .path7:before {
    content: "\e945";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path8:before {
    content: "\e946";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path9:before {
    content: "\e947";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path10:before {
    content: "\e948";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path11:before {
    content: "\e949";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path12:before {
    content: "\e94a";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path13:before {
    content: "\e94b";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path14:before {
    content: "\e94c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path15:before {
    content: "\e94d";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x .path16:before {
    content: "\e94e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path17:before {
    content: "\e94f";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path18:before {
    content: "\e950";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path19:before {
    content: "\e951";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path20:before {
    content: "\e952";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path21:before {
    content: "\e953";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path22:before {
    content: "\e954";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path23:before {
    content: "\e955";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path24:before {
    content: "\e956";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path25:before {
    content: "\e957";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path26:before {
    content: "\e958";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path27:before {
    content: "\e959";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path28:before {
    content: "\e95a";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path29:before {
    content: "\e962";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path30:before {
    content: "\e964";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path31:before {
    content: "\e965";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path32:before {
    content: "\e966";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path33:before {
    content: "\e967";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path34:before {
    content: "\e98a";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x .path35:before {
    content: "\e98b";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path36:before {
    content: "\e98c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path37:before {
    content: "\e98d";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path38:before {
    content: "\e98e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path39:before {
    content: "\e98f";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path40:before {
    content: "\e990";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x .path41:before {
    content: "\e991";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path42:before {
    content: "\e992";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path43:before {
    content: "\e993";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path44:before {
    content: "\e994";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path45:before {
    content: "\e995";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path46:before {
    content: "\e996";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path47:before {
    content: "\e997";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path48:before {
    content: "\e998";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path49:before {
    content: "\e999";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path50:before {
    content: "\e99a";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path51:before {
    content: "\e99b";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path52:before {
    content: "\e99c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path53:before {
    content: "\e99d";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path54:before {
    content: "\e99e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path55:before {
    content: "\e99f";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path56:before {
    content: "\e9a0";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path57:before {
    content: "\e9a1";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path58:before {
    content: "\e9a2";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path59:before {
    content: "\e9a3";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path60:before {
    content: "\e9a4";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path61:before {
    content: "\e9a5";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path62:before {
    content: "\e9a6";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path63:before {
    content: "\e9a7";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path64:before {
    content: "\e9a8";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path65:before {
    content: "\e9a9";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path66:before {
    content: "\e9aa";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path67:before {
    content: "\e9ab";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path68:before {
    content: "\e9ac";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path69:before {
    content: "\e9ad";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path70:before {
    content: "\e9ae";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path71:before {
    content: "\e9af";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path72:before {
    content: "\e9b0";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x .path73:before {
    content: "\e9b1";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x .path74:before {
    content: "\e9b2";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path75:before {
    content: "\e9b3";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x .path76:before {
    content: "\e9b4";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path77:before {
    content: "\e9b5";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x .path78:before {
    content: "\e9b6";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path79:before {
    content: "\e9b7";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x .path80:before {
    content: "\e9b8";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path81:before {
    content: "\e9b9";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path82:before {
    content: "\e9ba";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path83:before {
    content: "\e9bb";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path84:before {
    content: "\e9bc";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path85:before {
    content: "\e9bd";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path86:before {
    content: "\e9be";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x .path87:before {
    content: "\e9bf";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-view3x:before {
    content: "\ea32";
    color: #666;
  }
  .icon-logo-CP3x:before {
    content: "\e9ca";
    color: #fff;
  }
  .icon-logo-CCB:before {
    content: "\e9c7";
    color: #fff;
  }
  .icon-cookies3x .path1:before {
    content: "\e9c9";
    color: rgb(166, 208, 169);
  }
  .icon-cookies3x .path2:before {
    content: "\e9cb";
    margin-left: -1em;
    color: rgb(206, 149, 71);
  }
  .icon-cookies3x .path3:before {
    content: "\e9cc";
    margin-left: -1em;
    color: rgb(114, 68, 28);
  }
  .icon-cookies3x .path4:before {
    content: "\e9cd";
    margin-left: -1em;
    color: rgb(114, 68, 28);
  }
  .icon-cookies3x .path5:before {
    content: "\e9ce";
    margin-left: -1em;
    color: rgb(206, 149, 71);
  }
  .icon-cookies3x .path6:before {
    content: "\e9cf";
    margin-left: -1em;
    color: rgb(206, 149, 71);
  }
  .icon-cookies3x .path7:before {
    content: "\e9d0";
    margin-left: -1em;
    color: rgb(206, 149, 71);
  }
  .icon-cookies3x .path8:before {
    content: "\e9d1";
    margin-left: -1em;
    color: rgb(206, 149, 71);
  }
  .icon-cookies3x .path9:before {
    content: "\e9d2";
    margin-left: -1em;
    color: rgb(114, 68, 28);
  }
  .icon-cookies3x .path10:before {
    content: "\ea4f";
    margin-left: -1em;
    color: rgb(114, 68, 28);
  }
  .icon-cookies3x .path11:before {
    content: "\ea50";
    margin-left: -1em;
    color: rgb(114, 68, 28);
  }
  .icon-FAQ3x1:before {
    content: "\e9c0";
    color: #fff;
  }
  .icon-Hide3x1:before {
    content: "\e9c8";
    color: #666;
  }
  .icon-view3x1:before {
    content: "\e9c6";
    color: #666;
  }
  .icon-portal3x:before {
    content: "\ea51";
    color: #fff;
  }
  .icon-services3x:before {
    content: "\ea53";
    color: #fff;
  }
  .icon-store3x1:before {
    content: "\ea54";
    color: #fff;
  }
  .icon-icon-collapesd3x1:before {
    content: "\e95b";
    color: #fff;
  }
  .icon-coca-cola-logo-withe3x:before {
    content: "\e93f";
    color: #fff;
  }
  .icon-dot-empty3x1:before {
    content: "\e910";
    color: #fff;
  }
  .icon-dot-fill3x1:before {
    content: "\e911";
    color: #fff;
  }
  .icon-map3x1 .path1:before {
    content: "\e912";
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path2:before {
    content: "\ea39";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path3:before {
    content: "\ea3a";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path4:before {
    content: "\ea3b";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path5:before {
    content: "\ea3c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path6:before {
    content: "\ea3d";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x1 .path7:before {
    content: "\ea3e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path8:before {
    content: "\ea3f";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path9:before {
    content: "\ea40";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path10:before {
    content: "\ea41";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path11:before {
    content: "\ea42";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path12:before {
    content: "\ea43";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path13:before {
    content: "\ea44";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path14:before {
    content: "\ea45";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path15:before {
    content: "\ea46";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x1 .path16:before {
    content: "\ea47";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path17:before {
    content: "\ea48";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path18:before {
    content: "\ea49";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path19:before {
    content: "\ea4a";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path20:before {
    content: "\ea4b";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path21:before {
    content: "\ea4c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path22:before {
    content: "\ea4d";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path23:before {
    content: "\ea4e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path24:before {
    content: "\ea9c";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path25:before {
    content: "\ea9d";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path26:before {
    content: "\ea9e";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path27:before {
    content: "\ea9f";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path28:before {
    content: "\eaa0";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path29:before {
    content: "\eaa1";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path30:before {
    content: "\eaa2";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path31:before {
    content: "\eaa3";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path32:before {
    content: "\eaa4";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path33:before {
    content: "\eaa5";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path34:before {
    content: "\eaa6";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x1 .path35:before {
    content: "\eaa7";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path36:before {
    content: "\eaa8";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path37:before {
    content: "\eaa9";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path38:before {
    content: "\eaaa";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path39:before {
    content: "\eaab";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path40:before {
    content: "\eaac";
    margin-left: -0.7783203125em;
    color: rgb(197, 18, 53);
  }
  .icon-map3x1 .path41:before {
    content: "\eaad";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path42:before {
    content: "\eaae";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path43:before {
    content: "\eaaf";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path44:before {
    content: "\eab0";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path45:before {
    content: "\eab1";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path46:before {
    content: "\eab2";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path47:before {
    content: "\eab3";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path48:before {
    content: "\eab4";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path49:before {
    content: "\eab5";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path50:before {
    content: "\eab6";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path51:before {
    content: "\eab7";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path52:before {
    content: "\eab8";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path53:before {
    content: "\eab9";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path54:before {
    content: "\eaba";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path55:before {
    content: "\eabb";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path56:before {
    content: "\eabc";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path57:before {
    content: "\eabd";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path58:before {
    content: "\eabe";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path59:before {
    content: "\eabf";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path60:before {
    content: "\eac0";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path61:before {
    content: "\eac1";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path62:before {
    content: "\eac2";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path63:before {
    content: "\eac3";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path64:before {
    content: "\eac4";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path65:before {
    content: "\eac5";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path66:before {
    content: "\eac6";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path67:before {
    content: "\eac7";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path68:before {
    content: "\eac8";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path69:before {
    content: "\eac9";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path70:before {
    content: "\eaca";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path71:before {
    content: "\eacb";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path72:before {
    content: "\eacc";
    margin-left: -0.7783203125em;
    color: rgb(234, 234, 234);
  }
  .icon-map3x1 .path73:before {
    content: "\eacd";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x1 .path74:before {
    content: "\eace";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path75:before {
    content: "\eacf";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x1 .path76:before {
    content: "\ead0";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path77:before {
    content: "\ead1";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x1 .path78:before {
    content: "\ead2";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path79:before {
    content: "\ead3";
    margin-left: -0.7783203125em;
    color: rgb(0, 0, 0);
  }
  .icon-map3x1 .path80:before {
    content: "\ead4";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path81:before {
    content: "\ead5";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path82:before {
    content: "\eb01";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path83:before {
    content: "\eb02";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path84:before {
    content: "\eb03";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path85:before {
    content: "\eb04";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path86:before {
    content: "\eb05";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-map3x1 .path87:before {
    content: "\eb06";
    margin-left: -0.7783203125em;
    color: rgb(255, 255, 255);
  }
  .icon-menu-burguer3x:before {
    content: "\e95c";
    color: #fff;
  }
  .icon-profile3x .path1:before {
    content: "\e95d";
    color: rgb(255, 255, 255);
  }
  .icon-profile3x .path2:before {
    content: "\e97d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-profile3x .path3:before {
    content: "\e97e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-profile3x .path4:before {
    content: "\e97f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-profile3x .path5:before {
    content: "\e980";
    margin-left: -1em;
    color: rgb(197, 18, 53);
  }
  .icon-profile3x .path6:before {
    content: "\e981";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-select-down3x:before {
    content: "\e963";
    color: #fff;
  }
  .icon-Exito3x .path1:before {
    content: "\eb40";
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path2:before {
    content: "\eb41";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path3:before {
    content: "\eb42";
    margin-left: -1.0458984375em;
    color: rgb(0, 0, 0);
  }
  .icon-Exito3x .path4:before {
    content: "\eb43";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path5:before {
    content: "\eb44";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path6:before {
    content: "\eb45";
    margin-left: -1.0458984375em;
    color: rgb(45, 52, 71);
  }
  .icon-Exito3x .path7:before {
    content: "\eb46";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path8:before {
    content: "\eb47";
    margin-left: -1.0458984375em;
    color: rgb(29, 171, 64);
  }
  .icon-Exito3x .path9:before {
    content: "\eb48";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path10:before {
    content: "\eb49";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path11:before {
    content: "\eb4a";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path12:before {
    content: "\eb4b";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path13:before {
    content: "\eb4c";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path14:before {
    content: "\eb4d";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path15:before {
    content: "\eb4e";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path16:before {
    content: "\eb4f";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path17:before {
    content: "\eb50";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path18:before {
    content: "\eb51";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path19:before {
    content: "\eb52";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path20:before {
    content: "\eb53";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path21:before {
    content: "\eb54";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path22:before {
    content: "\eb55";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path23:before {
    content: "\eb56";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path24:before {
    content: "\eb57";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path25:before {
    content: "\eb58";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path26:before {
    content: "\eb59";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path27:before {
    content: "\eb5a";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path28:before {
    content: "\eb5b";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path29:before {
    content: "\eb5c";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path30:before {
    content: "\eb5d";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path31:before {
    content: "\eb5e";
    margin-left: -1.0458984375em;
    color: rgb(197, 18, 53);
  }
  .icon-Exito3x .path32:before {
    content: "\eb5f";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path33:before {
    content: "\eb60";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path34:before {
    content: "\eb61";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path35:before {
    content: "\eb62";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path36:before {
    content: "\eb63";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path37:before {
    content: "\eb64";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path38:before {
    content: "\eb65";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path39:before {
    content: "\eb66";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
  }
  .icon-Exito3x .path40:before {
    content: "\eb67";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path41:before {
    content: "\eb68";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path42:before {
    content: "\eb69";
    margin-left: -1.0458984375em;
    color: rgb(23, 16, 32);
  }
  .icon-Exito3x .path43:before {
    content: "\eb6a";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path44:before {
    content: "\eb6b";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path45:before {
    content: "\eb6c";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path46:before {
    content: "\eb6d";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path47:before {
    content: "\eb6e";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path48:before {
    content: "\eb6f";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path49:before {
    content: "\eb70";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path50:before {
    content: "\eb71";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path51:before {
    content: "\eb72";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path52:before {
    content: "\eb73";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path53:before {
    content: "\eb74";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path54:before {
    content: "\eb75";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path55:before {
    content: "\eb76";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path56:before {
    content: "\eb77";
    margin-left: -1.0458984375em;
    color: rgb(255, 255, 255);
    opacity: 0.25;
  }
  .icon-Exito3x .path57:before {
    content: "\eb78";
    margin-left: -1.0458984375em;
    color: rgb(0, 0, 0);
  }
  .icon-Exito3x .path58:before {
    content: "\eb79";
    margin-left: -1.0458984375em;
    color: rgb(29, 171, 64);
  }
  .icon-Exito3x .path59:before {
    content: "\eb7a";
    margin-left: -1.0458984375em;
    color: rgb(29, 171, 64);
  }
  .icon-Exito3x .path60:before {
    content: "\eb7b";
    margin-left: -1.0458984375em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path1:before {
    content: "\e922";
    color: rgb(197, 18, 53);
  }
  .icon-limite3x .path2:before {
    content: "\e923";
    margin-left: -1.125em;
    color: rgb(197, 18, 53);
  }
  .icon-limite3x .path3:before {
    content: "\e924";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path4:before {
    content: "\e925";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path5:before {
    content: "\e926";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path6:before {
    content: "\e927";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path7:before {
    content: "\e928";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path8:before {
    content: "\e929";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path9:before {
    content: "\e92a";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path10:before {
    content: "\e92b";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path11:before {
    content: "\e92c";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path12:before {
    content: "\e92d";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path13:before {
    content: "\e92e";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path14:before {
    content: "\e92f";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path15:before {
    content: "\e930";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path16:before {
    content: "\e931";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path17:before {
    content: "\e932";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path18:before {
    content: "\e933";
    margin-left: -1.125em;
    color: rgb(0, 0, 0);
  }
  .icon-limite3x .path19:before {
    content: "\e934";
    margin-left: -1.125em;
    color: rgb(255, 253, 253);
  }
  .icon-limite3x .path20:before {
    content: "\e935";
    margin-left: -1.125em;
    color: rgb(255, 253, 253);
  }
  .icon-limite3x .path21:before {
    content: "\e936";
    margin-left: -1.125em;
    color: rgb(45, 52, 71);
  }
  .icon-limite3x .path22:before {
    content: "\e937";
    margin-left: -1.125em;
    color: rgb(45, 52, 71);
  }
  .icon-limite3x .path23:before {
    content: "\e938";
    margin-left: -1.125em;
    color: rgb(197, 18, 53);
  }
  .icon-limite3x .path24:before {
    content: "\e939";
    margin-left: -1.125em;
    color: rgb(197, 18, 53);
  }
  .icon-limite3x .path25:before {
    content: "\e93a";
    margin-left: -1.125em;
    color: rgb(197, 18, 53);
  }
  .icon-limite3x .path26:before {
    content: "\e93b";
    margin-left: -1.125em;
    color: rgb(197, 18, 53);
  }
  .icon-Time3x .path1:before {
    content: "\e903";
    color: rgb(197, 18, 53);
  }
  .icon-Time3x .path2:before {
    content: "\e904";
    margin-left: -1.1044921875em;
    color: rgb(197, 18, 53);
  }
  .icon-Time3x .path3:before {
    content: "\e909";
    margin-left: -1.1044921875em;
    color: rgb(197, 18, 53);
  }
  .icon-Time3x .path4:before {
    content: "\e916";
    margin-left: -1.1044921875em;
    color: rgb(197, 18, 53);
  }
  .icon-Time3x .path5:before {
    content: "\e917";
    margin-left: -1.1044921875em;
    color: rgb(245, 166, 35);
  }
  .icon-Time3x .path6:before {
    content: "\e918";
    margin-left: -1.1044921875em;
    color: rgb(245, 166, 35);
  }
  .icon-Time3x .path7:before {
    content: "\e919";
    margin-left: -1.1044921875em;
    color: rgb(245, 166, 35);
  }
  .icon-Time3x .path8:before {
    content: "\e91a";
    margin-left: -1.1044921875em;
    color: rgb(245, 166, 35);
  }
  .icon-Time3x .path9:before {
    content: "\e91b";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path10:before {
    content: "\e91c";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path11:before {
    content: "\e91d";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path12:before {
    content: "\e91e";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path13:before {
    content: "\e91f";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path14:before {
    content: "\e920";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path15:before {
    content: "\e921";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path16:before {
    content: "\e95e";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path17:before {
    content: "\e95f";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path18:before {
    content: "\e960";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path19:before {
    content: "\e961";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path20:before {
    content: "\e968";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path21:before {
    content: "\e969";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Time3x .path22:before {
    content: "\e96a";
    margin-left: -1.1044921875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path1:before {
    content: "\ea63";
    color: rgb(37, 56, 63);
  }
  .icon-Acceso3x .path2:before {
    content: "\ea64";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path3:before {
    content: "\ea65";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path4:before {
    content: "\ea66";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path5:before {
    content: "\ea67";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path6:before {
    content: "\ea68";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path7:before {
    content: "\ea69";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path8:before {
    content: "\ea6a";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path9:before {
    content: "\ea6b";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path10:before {
    content: "\ea6c";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path11:before {
    content: "\ea6d";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path12:before {
    content: "\ea6e";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path13:before {
    content: "\ea6f";
    margin-left: -1.1875em;
    color: rgb(197, 18, 53);
  }
  .icon-Acceso3x .path14:before {
    content: "\ea70";
    margin-left: -1.1875em;
    color: rgb(197, 18, 53);
  }
  .icon-Acceso3x .path15:before {
    content: "\ea71";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path16:before {
    content: "\ea72";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path17:before {
    content: "\ea73";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path18:before {
    content: "\ea74";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path19:before {
    content: "\ea75";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path20:before {
    content: "\ea76";
    margin-left: -1.1875em;
    color: rgb(229, 229, 229);
  }
  .icon-Acceso3x .path21:before {
    content: "\ea77";
    margin-left: -1.1875em;
    color: rgb(229, 229, 229);
  }
  .icon-Acceso3x .path22:before {
    content: "\ea78";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path23:before {
    content: "\ea79";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path24:before {
    content: "\ea7a";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path25:before {
    content: "\ea7b";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path26:before {
    content: "\ea7c";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path27:before {
    content: "\ea7d";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path28:before {
    content: "\ea7e";
    margin-left: -1.1875em;
    color: rgb(229, 229, 229);
  }
  .icon-Acceso3x .path29:before {
    content: "\ea7f";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path30:before {
    content: "\ea80";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path31:before {
    content: "\ea81";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path32:before {
    content: "\ea82";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path33:before {
    content: "\ea83";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path34:before {
    content: "\ea84";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path35:before {
    content: "\ea85";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path36:before {
    content: "\ea86";
    margin-left: -1.1875em;
    color: rgb(197, 18, 53);
  }
  .icon-Acceso3x .path37:before {
    content: "\ea87";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path38:before {
    content: "\ea88";
    margin-left: -1.1875em;
    color: rgb(197, 18, 53);
  }
  .icon-Acceso3x .path39:before {
    content: "\ea89";
    margin-left: -1.1875em;
    color: rgb(244, 0, 9);
  }
  .icon-Acceso3x .path40:before {
    content: "\ea8a";
    margin-left: -1.1875em;
    color: rgb(197, 18, 53);
  }
  .icon-Acceso3x .path41:before {
    content: "\ea8b";
    margin-left: -1.1875em;
    color: rgb(255, 255, 255);
  }
  .icon-Acceso3x .path42:before {
    content: "\ea8c";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path43:before {
    content: "\ea8d";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path44:before {
    content: "\ea8e";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path45:before {
    content: "\ea8f";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path46:before {
    content: "\ea90";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path47:before {
    content: "\ea91";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path48:before {
    content: "\ea92";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path49:before {
    content: "\ea93";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path50:before {
    content: "\ea94";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path51:before {
    content: "\ea95";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path52:before {
    content: "\ea96";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path53:before {
    content: "\ea97";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path54:before {
    content: "\ea98";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path55:before {
    content: "\ea99";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Acceso3x .path56:before {
    content: "\ea9a";
    margin-left: -1.1875em;
    color: rgb(0, 0, 0);
  }
  .icon-Botella3x .path1:before {
    content: "\e96b";
    color: rgb(197, 18, 53);
  }
  .icon-Botella3x .path2:before {
    content: "\e982";
    margin-left: -0.3037109375em;
    color: rgb(255, 255, 254);
  }
  .icon-Botella3x .path3:before {
    content: "\e983";
    margin-left: -0.3037109375em;
    color: rgb(197, 18, 53);
  }
  .icon-Botella3x .path4:before {
    content: "\e984";
    margin-left: -0.3037109375em;
    color: none;
  }
  .icon-Botella3x .path5:before {
    content: "\e985";
    margin-left: -0.3037109375em;
    color: rgb(197, 18, 53);
  }
  .icon-Botella3x .path6:before {
    content: "\e986";
    margin-left: -0.3037109375em;
    color: none;
  }
  .icon-Miss3x .path1:before {
    content: "\e96c";
    color: rgb(255, 255, 255);
  }
  .icon-Miss3x .path2:before {
    content: "\e987";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path3:before {
    content: "\e988";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path4:before {
    content: "\e989";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path5:before {
    content: "\e9c3";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path6:before {
    content: "\e9c4";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path7:before {
    content: "\e9c5";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path8:before {
    content: "\e9d3";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path9:before {
    content: "\e9d4";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path10:before {
    content: "\e9d5";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path11:before {
    content: "\e9d6";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path12:before {
    content: "\e9d7";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path13:before {
    content: "\e9d8";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path14:before {
    content: "\e9d9";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path15:before {
    content: "\e9da";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path16:before {
    content: "\e9db";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path17:before {
    content: "\e9dc";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path18:before {
    content: "\e9dd";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path19:before {
    content: "\e9de";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path20:before {
    content: "\e9df";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path21:before {
    content: "\e9e0";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path22:before {
    content: "\e9e1";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path23:before {
    content: "\e9e2";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path24:before {
    content: "\e9e3";
    margin-left: -1.076171875em;
    color: rgb(0, 0, 0);
  }
  .icon-Miss3x .path25:before {
    content: "\e9e4";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path26:before {
    content: "\e9e5";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path27:before {
    content: "\e9e6";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path28:before {
    content: "\e9e7";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path29:before {
    content: "\e9e8";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path30:before {
    content: "\e9e9";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path31:before {
    content: "\e9ea";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path32:before {
    content: "\e9eb";
    margin-left: -1.076171875em;
    color: rgb(255, 255, 255);
  }
  .icon-Miss3x .path33:before {
    content: "\e9ec";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-Miss3x .path34:before {
    content: "\e9ed";
    margin-left: -1.076171875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path1:before {
    content: "\e9c1";
    color: rgb(37, 56, 63);
  }
  .icon-contrasena3x .path2:before {
    content: "\e9ee";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path3:before {
    content: "\e9ef";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path4:before {
    content: "\e9f0";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path5:before {
    content: "\e9f1";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path6:before {
    content: "\e9f2";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path7:before {
    content: "\e9f3";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path8:before {
    content: "\e9f4";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path9:before {
    content: "\e9f5";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path10:before {
    content: "\e9f6";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path11:before {
    content: "\e9f7";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path12:before {
    content: "\e9f8";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path13:before {
    content: "\e9f9";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path14:before {
    content: "\e9fa";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path15:before {
    content: "\e9fb";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path16:before {
    content: "\e9fc";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path17:before {
    content: "\e9fd";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path18:before {
    content: "\e9fe";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path19:before {
    content: "\e9ff";
    margin-left: -1.1669921875em;
    color: rgb(229, 229, 229);
  }
  .icon-contrasena3x .path20:before {
    content: "\ea00";
    margin-left: -1.1669921875em;
    color: rgb(229, 229, 229);
  }
  .icon-contrasena3x .path21:before {
    content: "\ea01";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path22:before {
    content: "\ea02";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path23:before {
    content: "\ea03";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path24:before {
    content: "\ea04";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path25:before {
    content: "\ea05";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path26:before {
    content: "\ea06";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path27:before {
    content: "\ea07";
    margin-left: -1.1669921875em;
    color: rgb(229, 229, 229);
  }
  .icon-contrasena3x .path28:before {
    content: "\ea08";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path29:before {
    content: "\ea09";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path30:before {
    content: "\ea0a";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path31:before {
    content: "\ea0b";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path32:before {
    content: "\ea0c";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path33:before {
    content: "\ea0d";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path34:before {
    content: "\ea0e";
    margin-left: -1.1669921875em;
    color: rgb(244, 0, 9);
  }
  .icon-contrasena3x .path35:before {
    content: "\ea0f";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path36:before {
    content: "\ea10";
    margin-left: -1.1669921875em;
    color: rgb(255, 255, 255);
  }
  .icon-contrasena3x .path37:before {
    content: "\ea11";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path38:before {
    content: "\ea12";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path39:before {
    content: "\ea13";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path40:before {
    content: "\ea14";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path41:before {
    content: "\ea15";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path42:before {
    content: "\ea16";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path43:before {
    content: "\ea17";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path44:before {
    content: "\ea18";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path45:before {
    content: "\ea19";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path46:before {
    content: "\ea1a";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path47:before {
    content: "\ea1b";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path48:before {
    content: "\ea1c";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path49:before {
    content: "\ea1d";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path50:before {
    content: "\ea1e";
    margin-left: -1.1669921875em;
    color: rgb(0, 0, 0);
  }
  .icon-contrasena3x .path51:before {
    content: "\ea1f";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-contrasena3x .path52:before {
    content: "\ea20";
    margin-left: -1.1669921875em;
    color: rgb(197, 18, 53);
  }
  .icon-good3x .path1:before {
    content: "\e9c2";
    color: rgb(229, 229, 229);
  }
  .icon-good3x .path2:before {
    content: "\ea21";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path3:before {
    content: "\ea22";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path4:before {
    content: "\ea23";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path5:before {
    content: "\ea24";
    margin-left: -1.056640625em;
    color: rgb(197, 18, 53);
  }
  .icon-good3x .path6:before {
    content: "\ea25";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path7:before {
    content: "\ea26";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path8:before {
    content: "\ea27";
    margin-left: -1.056640625em;
    color: rgb(37, 56, 63);
  }
  .icon-good3x .path9:before {
    content: "\ea28";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path10:before {
    content: "\ea2a";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path11:before {
    content: "\ea2b";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path12:before {
    content: "\ea2c";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path13:before {
    content: "\ea2d";
    margin-left: -1.056640625em;
    color: rgb(197, 18, 53);
  }
  .icon-good3x .path14:before {
    content: "\ea2e";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path15:before {
    content: "\ea2f";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path16:before {
    content: "\ea30";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path17:before {
    content: "\ea31";
    margin-left: -1.056640625em;
    color: rgb(197, 18, 53);
  }
  .icon-good3x .path18:before {
    content: "\ea33";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path19:before {
    content: "\ea34";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path20:before {
    content: "\ea35";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path21:before {
    content: "\ea36";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path22:before {
    content: "\ea37";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path23:before {
    content: "\ea38";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path24:before {
    content: "\ea55";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path25:before {
    content: "\ea56";
    margin-left: -1.056640625em;
    color: rgb(197, 18, 53);
  }
  .icon-good3x .path26:before {
    content: "\ea57";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path27:before {
    content: "\ea58";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path28:before {
    content: "\ea59";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path29:before {
    content: "\ea5a";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path30:before {
    content: "\ea5b";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path31:before {
    content: "\ea5c";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path32:before {
    content: "\ea5d";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path33:before {
    content: "\ea5e";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path34:before {
    content: "\ea5f";
    margin-left: -1.056640625em;
    color: rgb(229, 229, 229);
  }
  .icon-good3x .path35:before {
    content: "\ea9b";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path36:before {
    content: "\ead6";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path37:before {
    content: "\ead7";
    margin-left: -1.056640625em;
    color: rgb(229, 229, 229);
  }
  .icon-good3x .path38:before {
    content: "\ead8";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path39:before {
    content: "\ead9";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path40:before {
    content: "\eada";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path41:before {
    content: "\eb07";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path42:before {
    content: "\eb08";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path43:before {
    content: "\eb09";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path44:before {
    content: "\eb0a";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path45:before {
    content: "\eb0b";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path46:before {
    content: "\eb0c";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path47:before {
    content: "\eb0d";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path48:before {
    content: "\eb0e";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path49:before {
    content: "\eb0f";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path50:before {
    content: "\eb10";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path51:before {
    content: "\eb11";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path52:before {
    content: "\eb12";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path53:before {
    content: "\eb13";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path54:before {
    content: "\eb14";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path55:before {
    content: "\eb15";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path56:before {
    content: "\eb16";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path57:before {
    content: "\eb17";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path58:before {
    content: "\eb18";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path59:before {
    content: "\eb19";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path60:before {
    content: "\eb1a";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path61:before {
    content: "\eb1b";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path62:before {
    content: "\eb1c";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path63:before {
    content: "\eb1d";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path64:before {
    content: "\eb1e";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path65:before {
    content: "\eb1f";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path66:before {
    content: "\eb20";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path67:before {
    content: "\eb21";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path68:before {
    content: "\eb22";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path69:before {
    content: "\eb23";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path70:before {
    content: "\eb24";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path71:before {
    content: "\eb25";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path72:before {
    content: "\eb26";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path73:before {
    content: "\eb27";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path74:before {
    content: "\eb28";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path75:before {
    content: "\eb29";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path76:before {
    content: "\eb2a";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path77:before {
    content: "\eb2b";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path78:before {
    content: "\eb2c";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path79:before {
    content: "\eb2d";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path80:before {
    content: "\eb2e";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path81:before {
    content: "\eb2f";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path82:before {
    content: "\eb30";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path83:before {
    content: "\eb31";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path84:before {
    content: "\eb32";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path85:before {
    content: "\eb33";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path86:before {
    content: "\eb34";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path87:before {
    content: "\eb35";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path88:before {
    content: "\eb36";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path89:before {
    content: "\eb37";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path90:before {
    content: "\eb38";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path91:before {
    content: "\eb39";
    margin-left: -1.056640625em;
    color: rgb(255, 255, 255);
  }
  .icon-good3x .path92:before {
    content: "\eb3a";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-good3x .path93:before {
    content: "\eb3b";
    margin-left: -1.056640625em;
    color: rgb(26, 26, 26);
  }
  .icon-metodo .path1:before {
    content: "\ea29";
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path2:before {
    content: "\eadb";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path3:before {
    content: "\eadc";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path4:before {
    content: "\eadd";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path5:before {
    content: "\eade";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path6:before {
    content: "\eadf";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path7:before {
    content: "\eae0";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path8:before {
    content: "\eae1";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path9:before {
    content: "\eae2";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path10:before {
    content: "\eae3";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path11:before {
    content: "\eae4";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path12:before {
    content: "\eae5";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path13:before {
    content: "\eae6";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path14:before {
    content: "\eae7";
    margin-left: -1.349609375em;
    color: rgb(45, 52, 71);
  }
  .icon-metodo .path15:before {
    content: "\eae8";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path16:before {
    content: "\eae9";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path17:before {
    content: "\eaea";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path18:before {
    content: "\eaeb";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path19:before {
    content: "\eaec";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path20:before {
    content: "\eaed";
    margin-left: -1.349609375em;
    color: rgb(197, 18, 53);
  }
  .icon-metodo .path21:before {
    content: "\eaee";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path22:before {
    content: "\eaef";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path23:before {
    content: "\eaf0";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path24:before {
    content: "\eaf1";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path25:before {
    content: "\eaf2";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path26:before {
    content: "\eaf3";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path27:before {
    content: "\eaf4";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path28:before {
    content: "\eaf5";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path29:before {
    content: "\eaf6";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path30:before {
    content: "\eaf7";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path31:before {
    content: "\eaf8";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path32:before {
    content: "\eaf9";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path33:before {
    content: "\eafa";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path34:before {
    content: "\eafb";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path35:before {
    content: "\eafc";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path36:before {
    content: "\eafd";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path37:before {
    content: "\eafe";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  .icon-metodo .path38:before {
    content: "\eaff";
    margin-left: -1.349609375em;
    color: rgb(255, 255, 255);
  }
  .icon-metodo .path39:before {
    content: "\eb00";
    margin-left: -1.349609375em;
    color: rgb(0, 0, 0);
  }
  