$width-menu: 6rem;
$footer-height: 180px;
$header-height: 5.5rem;

// * COLORS
$black-color: #000000;
$black-light-color: #333333;
$gray-color: #B6B6B6;
$gray-ligth-color: #E1E1E1;
$gray-dark-color: #999999;
$white-color: #FFFFFF;
$primary-color: #F70000;
$primary-dark-color: #C51235;
$coal-color: #2d3447;
$green-color: #1DAB40;

$_z1: 1;
$_z2: 2;
$_z3: 3;
$_z4: 4;
$_z5: 5;
$_z6: 6;
$_z7: 7;
$_z8: 8;
$_z9: 9;
$_z10: 10;

// * Break points from bootstrap
$sm: 576px; // ≥/<
$md: 768px; // ≥
$lg: 992px; // ≥
$xl: 1200px; // ≥
$xxl: 1400px; // ≥

%ff-unity-headline-bold {
    font-family: 'Unity Headline Bold';
}
%ff-unity-headline-medium {
    font-family: 'Unity Headline Medium';
}
%ff-unity-headline-light {
    font-family: 'Unity Headline Light';
}
%ff-unity-headline-regular {
    font-family: 'Unity Headline';
}

%fs36-lh40 {
    font-size: 2.25rem; // 36px;
    letter-spacing: 0;
    line-height: 2.5rem; // 40px;
    @media (max-width: $md) {
        font-size: 1.125rem; // 18px;
        line-height: 1.5rem; // 24px;
    }
}
%fs32-lh40 {
    font-size: 2rem; // 32px;
    letter-spacing: 0;
    line-height: 2.5rem; // 40px;
}
%fs28-lh40 {
    font-size: 1.75rem; // 26px;
    letter-spacing: 0;
    line-height: 2.5rem; // 40px;
}
%fs26-lh40 {
    font-size: 1.625rem; // 26px;
    letter-spacing: 0;
    line-height: 2.5rem; // 40px;
}
%fs24-lh32 {
    font-size: 1.5rem; // 24px;
    letter-spacing: 0;
    line-height: 2rem; // 32px;
}
%fs20-lh24 {
    font-size: 1.25rem; // 20px;
    letter-spacing: 0;
    line-height: 1.5rem; // 24px;
}
%fs18-lh24 {
    font-size: 1.125rem; // 18px;
    letter-spacing: 0;
    line-height: 1.5rem; // 24px;
}
%fs16-lh24 {
    font-size: 1rem; // 16px;
    letter-spacing: 0;
    line-height: 1.5rem; // 24px;
}
%fs14-lh24 {
    font-size: 0.875rem; // 14px;
    letter-spacing: 0;
    line-height: 1.5rem; // 24px;
}
%fs12-lh16 {
    font-size: 0.75rem; // 12px;
    letter-spacing: 0;
    line-height: 1rem; // 16px;
}

